import React, { FC } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';

import { ParagraphLarge } from '../generic/Text';
import { Container, Section } from '../generic/View';

type Props = DeepExtractType<
  GatsbyTypes.PartnershipPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'watch_tower_section']
>;

const WatchTowerSection: FC<Props> = ({ image, catch_line }) => {
  return (
    <Section>
      <Container className="flex flex-col items-end">
        <div className="sm:w-[17.25rem] md:w-3/4 lg:w-[51.875rem]">
          <div className="relative">
            <div
              className={`bg-center bg-no-repeat bg-cover relative
                          mb-ms md:mb-mm
                          w-full
                          h-[11rem] md:h-100 lg:h-[33.5rem]`}
              style={{
                backgroundImage: `url(${image?.source})`
              }}
            ></div>
          </div>

          <ParagraphLarge className="w-full md:max-w-[37rem]">
            {catch_line}
          </ParagraphLarge>
        </div>
      </Container>
    </Section>
  );
};

export default WatchTowerSection;

import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DeepExtractType } from 'ts-deep-extract-types';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import InvestmentModelSection from '../components/partnershipSections/InvestmentModelSection';
import WatchTowerSection from '../components/partnershipSections/WatchTowerSection';

type Props = DeepExtractType<
  GatsbyTypes.PartnershipPageTemplateQuery,
  ['markdownRemark', 'frontmatter']
>;

export const PartnershipPageTemplate: FC<Props> = ({
  header_section,
  investment_model_section,
  watch_tower_section,
  footer_section
}) => {
  return (
    <>
      <section>
        <Hero
          title={header_section?.heading || ''}
          imageSource={header_section?.bg_image?.source || ''}
        />
      </section>
      <InvestmentModelSection
        heading={investment_model_section?.heading}
        column1={investment_model_section?.column1}
        column2={investment_model_section?.column2}
        column3={investment_model_section?.column3}
      />
      <WatchTowerSection
        image={watch_tower_section?.image}
        catch_line={watch_tower_section?.catch_line}
      />
      <Footer richText={footer_section?.rich_text || ''} />
    </>
  );
};

const IndexPage: FC<{ data: GatsbyTypes.PartnershipPageTemplateQuery }> = ({
  data
}) => (
  <Layout
    pageTitle={data.markdownRemark?.frontmatter?.pageTitle || ''}
    metaTagDescription={
      data.markdownRemark?.frontmatter?.meta_tag_description || ''
    }
  >
    <PartnershipPageTemplate
      meta_tag_description={
        data.markdownRemark?.frontmatter?.meta_tag_description
      }
      pageTitle={data.markdownRemark?.frontmatter?.pageTitle}
      header_section={data.markdownRemark?.frontmatter?.header_section}
      investment_model_section={
        data.markdownRemark?.frontmatter?.investment_model_section
      }
      watch_tower_section={
        data.markdownRemark?.frontmatter?.watch_tower_section
      }
      footer_section={data.markdownRemark?.frontmatter?.footer_section}
    />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query PartnershipPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "partnership-page" } }) {
      frontmatter {
        pageTitle
        meta_tag_description
        header_section {
          bg_image {
            source
          }
          heading
        }
        investment_model_section {
          heading
          column1 {
            heading
            rich_text
          }
          column2 {
            heading
            rich_text
          }
          column3 {
            heading
            rich_text
          }
        }
        watch_tower_section {
          image {
            source
            alt
          }
          catch_line
        }
        footer_section {
          rich_text
        }
      }
    }
  }
`;

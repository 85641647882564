import React, { FC } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';

import { Heading3, Heading4 } from '../generic/Text';
import { Container, Section } from '../generic/View';
import Content from '../RichContent';

type Props = DeepExtractType<
  GatsbyTypes.PartnershipPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'investment_model_section']
>;

const InvestmentModelSection: FC<Props> = ({
  heading,
  column1,
  column2,
  column3
}) => {
  return (
    <Section withGradient>
      <Container>
        <Heading4 borderBottom className="mb-ms md:mb-mm">
          {heading}
        </Heading4>
        <div className="flex flex-col lg:flex-row lg:justify-between">
          <div className="mb-ms lg:mb-0 lg:w-84.5">
            <Heading3 className="mb-3 md:mb-ms">{column1?.heading}</Heading3>
            <Content markdown={column1?.rich_text} />
          </div>
          <div className="mb-ms lg:mb-0 lg:w-84.5">
            <Heading3 className="mb-3 md:mb-ms">{column2?.heading}</Heading3>
            <Content markdown={column2?.rich_text} />
          </div>
          <div className="mb-ms lg:mb-0 lg:w-84.5">
            <Heading3 className="mb-3 md:mb-ms">{column3?.heading}</Heading3>
            <Content markdown={column3?.rich_text} />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default InvestmentModelSection;

import classNames from 'classnames';
import React, { FC } from 'react';
import { Heading1 } from './generic/Text';
import { Container } from './generic/View';

const Hero: FC<{
  title: string;
  imageSource: string;
  className?: string;
}> = ({ title, imageSource, className }) => {
  return (
    <div
      className={classNames(
        `h-64 md:h-[41.5rem] bg-center bg-no-repeat bg-cover white`,
        className
      )}
      style={{ backgroundImage: `url('${imageSource}')` }}
    >
      <Container>
        <Heading1 className="w-full md:w-3/4 lg:w-[51rem] left-0 absolute bottom-4 md:bottom-12">
          {title}
        </Heading1>
      </Container>
    </div>
  );
};

export default Hero;
